// Fonts
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap");

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


@import '~bootstrap-steps/scss/bootstrap-steps';

.btn-primary {
    color: #fff;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    border-color: $iepac;
}

.card-footer {
    color:#fff;
    background-color: $secondary;
}

.form-control {
    color: #232424;
}

.form-control:focus {
    border-color:$primary;
    // box-shadow: 0 0 0 0.2rem rgba(255, 102, 0, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(239, 127, 26, 0.25);
}

.cursor-pointer{
    cursor: pointer;
}

.step-content {
    min-width:6rem;
}

.step-circle {
    height:2.1rem;
    width: 2.5rem;
}

.text-justify{
    text-align: justify;
}


// @media (max-width: 767px) {
//     .table-responsive .dropdown-menu {
//         position: static !important;
//     }
// }
// @media (min-width: 768px) {
//     .table-responsive {
//         overflow: inherit;
//     }
// }

.step-info {
    .step-circle {
      color: $iepac-blue-light;
      background-color: white;
      border-color: $iepac-blue-light;
      &::before {
        color: $iepac-blue-light;
      }
    }
    .step-text {
      color: $iepac-blue-light;
    }
  }

table thead {
    text-transform: none !important;
}
