// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Rubik', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$iepac:#FF6600;
$iepac-primary: #ef7f1a !default;
$iepac-secondary: #323d47 !default;
$iepac-blue-secondary: #207a6a !default;
$iepac-blue-light: #82d4c4 !default;

$primary: $iepac-primary;
$secondary: $iepac-secondary;
$info: $iepac-blue-light;
$success: $iepac-blue-secondary;




